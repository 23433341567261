.profile-menu-anchor {
    color: #98A2B3;
}

.profile-menu-portrait {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #FAFAFA;

}

.sign-out-option {
    color: #F04438;
}
