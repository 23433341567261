.button-custom {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
}

.button-icon {
    height: 24px;
}

.button-text {

}

.button-icon + .button-text {
    margin-left: 12px;
}
